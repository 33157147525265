<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="7">
          <b-card>
            <b-row>
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Poste de Referencia
                  </h6>
                  <b-form-input
                    v-model="fallaBt.posteReferencia"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Tipo de Falla
                  </h6>
                  <v-select
                    v-model="fallaBt.tipoFalla"
                    label="nombre"
                    :disabled="editable"
                    :options="tiposFalla"
                    @input="mostrarSeleccionado"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="fallaBt.tipoFalla && (fallaBt.tipoFalla.id === 1 || fallaBt.tipoFalla.id === 2 || fallaBt.tipoFalla.id === 3 || fallaBt.tipoFalla.id === 4)">
              <b-col
                v-if="fallaBt.tipoFalla && fallaBt.tipoFalla.id === 2"
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Número de Transformador
                  </h6>
                  <b-form-input
                    v-model="fallaBt.noTransformador"
                    :disabled="editable"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Solución Provisional
                  </h6>
                  <b-form-textarea
                    v-model="fallaBt.solucionProvisional"
                    :disabled="editable"
                    rows="2"
                    no-resize
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Dictamen
                  </h6>
                  <b-form-textarea
                    v-model="fallaBt.dictamen"
                    :disabled="editable"
                    rows="2"
                    no-resize
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Recomendación
                  </h6>
                  <b-form-textarea
                    v-model="fallaBt.recomendacion"
                    :disabled="editable"
                    rows="2"
                    no-resize
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Observaciones
                  </h6>
                  <b-form-textarea
                    v-model="fallaBt.observaciones"
                    :disabled="editable"
                    rows="2"
                    no-resize
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <!-- BOTONES -->
              <b-col
                v-if="editable"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="changeEditable"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Editar</span>
                </b-button>
              </b-col>
              <b-col
                v-if="!editable"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="updateItem"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Guardar</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="cancel"
                >
                  <feather-icon
                    icon="SlashIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="5">
          <b-card>
            <b-row>
              <b-col cols="12">
                <h3
                  style="display: flex; justify-content: center;"
                  class="text-primary"
                >
                  Fotografías de la Falla
                </h3>
                <b-carousel
                  id="carousel-caption"
                  controls
                  indicators
                  :interval="6000"
                >

                  <b-carousel-slide
                    v-for="item in fallaBt.seguimiento"
                    :key="item.tipo"
                    class="card-img-overlay"
                    :img-src="item.url"
                    style="height: 500px; width: 570px"
                  />

                </b-carousel></b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BCard, BButton, VBModal, BFormTextarea, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getFalla, updateCreateFalla } from '@/utils/mantenimiento/fallasBt/fallasBtUtils'
import { getlistado } from '@/utils/catalogos'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      urlImagen: '',
      id: router.currentRoute.params.id,
      fallaBt: {},
      tiposFalla: [],
      editable: router.currentRoute.params.action === 'Edit',
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async created() {
    this.tiposFalla = await getlistado(15, 'Tipos de Falla BT', false, this.usuario)
    this.fallaBt = await getFalla(this.id)
  },

  methods: {
    mostrarSeleccionado() {
      console.log(this.fallaBt.tipoFalla)
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    async cancel() {
      await this.$router.replace('/falla-linea-bt')
    },
    // eslint-disable-next-line no-empty-function
    async updateItem() {
      const result = await updateCreateFalla(this.fallaBt, 1)
      if (result !== null) {
        this.$router.replace('/falla-linea-bt').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Falla BT',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Falla actualizada correctamente!',
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Falla BT\'',
            icon: 'FrownIcon',
            variant: 'warning',
            text: 'Ocurrió un problema actualizando la falla, intente nuevamente!',
          },
        })
      }
    },
    fotografiaPanoramica(file) {
      this.fallaBt.fotoPanoramica = file
    },
    fotografiaSolucion(file) {
      this.fallaBt.fotoSolucion = file
    },
    fotografiaSeguimiento(file) {
      this.fallaBt.fotoSeguimiento = file
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
